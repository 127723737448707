<template>
  <router-view />
</template>

<script>
export default {
  name: 'MaterialsMedicines',
  components: {
  },
}
</script>